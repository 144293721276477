<template>
	<b-modal
		centered
		:title="creationModal.title"
		title-class="title-register-ejemplares"
		v-model="isActive"
		size="lg"
		@hidden="handleHidden"
	>
		<b-overlay :show="creationModal.loading">
			<b-input-group>
				<b-form-input
					placeholder="Ingrese placa o alias"
					v-model="creationModal.search"
					@keyup.enter="searchSpecimen"
				/>
				<b-input-group-append>
					<b-button variant="outline-primary" @click="searchSpecimen">
						<feather-icon icon="SearchIcon" />
					</b-button>
				</b-input-group-append>
			</b-input-group>
			<template v-if="!!creationModal.opts.length">
				<h4 class="my-1">Seleccionar</h4>
				<b-row>
					<b-col class="mb-1" cols="6" v-for="(specimen, idx) in creationModal.opts" :key="specimen.id">
						<b-card class="p-0" no-body>
							<div class="position-relative clickable hoverTriggerResult">
								<SpecimenPlate :specimen="specimen" />
								<div class="deleteOverlaySuccess" @click="selectOpt(specimen)">
									<feather-icon icon="PlusIcon" size="25" />
								</div>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</template>
			<hr class="border-dark" />
			<h4 class="mb-1">Ejemplares</h4>
			<b-row>
				<template v-for="(specimen, idx) in creationModal.specimens">
					<b-col class="" md="12" lg="6" :key="idx" v-if="!specimen.delete">
						<b-card no-body style="padding: 5px">
							<div class="position-relative hoverTrigger flex-column">
								<div
									class="d-flex justify-content-between w-full align-items-center"
									style="padding-bottom: 5px"
								>
									<div class="" v-b-tooltip.hover="`Torneos jugados`">
										<DoubleFlag :variant="`${specimen.playeds > 0 ? 'primary' : 'secondary'}`" />
										<span class="font-weight-bold" style="font-size: 13px; color: #8d8a85">
											({{ specimen.playeds }})
										</span>
									</div>
									<div class="" style="">
										<b-badge
											v-if="specimen.is_recommended > 0"
											variant="light-primary"
											class="status-label"
										>
											<span style="font-size: 10px; display: block; margin-bottom: 0.2rem">
												Recomendado por:
											</span>
											<span style="font-size: 10px; display: block">
												{{ specimen.recommended_by }}
											</span>
										</b-badge>
									</div>
								</div>
								<div class="d-flex">
									<SpecimenPlate :specimen="specimen" assignableAlias />
									<div
										class="average-top d-flex flex-column align-items-center justify-content-center"
									>
										<span
											class="text-success font-weight-bold d-flex align-items-center"
											style="
												height: 20px;
												width: 115px;
												border-bottom: 1px solid rgb(203 203 203);
											"
										>
											<feather-icon class="text-success" size="16" icon="StarIcon" />
											<span style="width: 35px" class="text-center">
												{{ specimen.average_score.average }}
											</span>
											- topes
										</span>
										<span
											class="text-primary font-weight-bold d-flex align-items-center"
											style="
												height: 20px;
												width: 115px;
												border-bottom: 1px solid rgb(203 203 203);
											"
										>
											<feather-icon size="16" icon="StarIcon" class="text-primary" />
											<span style="width: 35px" class="text-center">
												{{ specimen.average_score_total.average }}
											</span>
											- general
										</span>
										<span
											class="text-primary font-weight-bold d-flex align-items-center"
											style="
												height: 20px;
												width: 115px;
												border-bottom: 1px solid rgb(203 203 203);
											"
										>
											<span style="width: 30px">{{ "Kg - " }}</span>
											<!-- <feather-icon
												size="16"
												icon="StarIcon"
												class="text-primary"
												style="margin-left: 5px"
											/> -->
											<span class="text-center">
												{{ specimen.weight.kg_weight }}
											</span>
										</span>
										<span
											class="text-primary font-weight-bold d-flex align-items-center"
											style="
												height: 20px;
												width: 115px;
												border-bottom: 1px solid rgb(203 203 203);
											"
										>
											<span style="width: 30px">{{ "Lb - " }}</span>
											<!-- <feather-icon
												size="16"
												icon="StarIcon"
												class="text-primary"
												style="margin-left: 5px"
											/> -->
											<span class="text-center">
												{{ specimen.weight.pounds_weight }}
											</span>
										</span>
									</div>
								</div>

								<div class="d-flex justify-content-between w-100" style="padding: 5px">
									<div class="d-flex justify-content-center" style="width: 95px">
										<small
											class="text-primary font-weight-bold mt-25 text-uppercase"
											v-if="!!specimen.status_tournament_event_id"
										>
											{{ specimen.status_tournament_event }}
										</small>
										<small class="text-danger font-weight-bold mt-25 text-uppercase" v-else>
											SIN GUARDAR
										</small>
									</div>
									<div class="" v-if="specimen.nursing_status_id">
										<b-badge class="text-uppercase mt-25" variant="secondary">
											En enfermeria
										</b-badge>
									</div>
									<div class="d-flex" style="">
										<div
											class="deleteOverlay"
											style="margin-right: 5px"
											@click="removeFromCreation(idx)"
											v-b-tooltip.hover="`Remover ejemplar`"
										>
											<feather-icon icon="Trash2Icon" size="25" />
										</div>
										<div
											class="deleteOverlay deleteOverlayEdit"
											v-b-tooltip.hover="`Asignar alias`"
											@click="assignSpecimenAlias(specimen, idx)"
											v-if="!specimen.alias"
										>
											<feather-icon icon="EditIcon" size="25" />
										</div>
									</div>
									<!-- <template> -->
									<!-- </template> -->
								</div>
							</div>
						</b-card>
					</b-col>
				</template>
			</b-row>
		</b-overlay>
		<template #modal-footer>
			<small class="text-primary">*Los alias se podran asignar los ejemplares guardados y sin alias</small>
			<b-button variant="success" @click="assignSpecimens">Guardar</b-button>
		</template>
		<AssignAliasModal
			:show="assignAliases.show"
			:info="assignAliases.info"
			@closing="assignAliases.show = false"
			@assigned="setAssignedAlias"
		/>
	</b-modal>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import tournamentService from "@/services/tournament/default.service"
import moment from "moment"

import AssignAliasModal from "./AssignAliasModal.vue"
import DoubleFlag from "@/icons/training/DoubleFlag.vue"

export default {
	components: { SpecimenPlate, AssignAliasModal, DoubleFlag },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		//
		creationModal: {
			show: false,
			loading: false,
			title: null,
			search: null,
			opts: [],
			frontIdx: null,
			specimens: [],
		},
		assignAliases: { show: false, info: {} },
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.creationModal = {
				show: false,
				loading: false,
				title: null,
				search: null,
				opts: [],
				frontIdx: null,
				specimens: [],
			}
			this.$emit("closing")
		},
		async searchSpecimen() {
			this.creationModal.opts = []
			if (!this.creationModal.search || this.creationModal.search.length < 2) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					"La busqueda debe ser de al menos 2 caracteres"
				)
				return
			}
			this.creationModal.loading = true
			const { data } = await tournamentService.searchSpecimens({ search: this.creationModal.search })
			this.creationModal.search = null
			let opts = data.data.map((opt) => ({
				...opt,
				additional_plate: opt.additional_plate == opt.plate ? undefined : opt.additional_plate,
			}))
			if (opts.length == 0) {
				// sin resultados
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					"No se encontraron resultados para la busqueda"
				)
				this.creationModal.loading = false
				return
			}
			if (opts.length == 1) {
				if (opts[0].events && opts[0].events.length > 0) {
					const specimenActive = opts[0].events.find((event) => event.active_fronts > 0)
					if (specimenActive) {
						this.showToast(
							"warning",
							"top-right",
							"Advertencia",
							"AlertTriangleIcon",
							`Ejemplar ${opts[0].alias || opts[0].plate} esta registrado en la fecha ${moment(
								opts[0].events[0].te_date
							).format("MM-DD-YYYY")} del torneo ${opts[0].events[0].to_name}`
						)
						this.creationModal.loading = false
						return
					}
				}
				if (opts[0].recommended && opts[0].recommended.length > 0) {
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"AlertTriangleIcon",
						`Ejemplar ${opts[0].alias || opts[0].plate} esta recomendado en la fecha ${moment(
							opts[0].recommended[0].te_date
						).format("MM-DD-YYYY")} del torneo ${opts[0].recommended[0].to_name}`
					)
					this.creationModal.loading = false
					return
				}
				// un resultado
				if (this.hasRepeated(opts[0], this.creationModal.specimens)) {
					this.showToast(
						"danger",
						"top-right",
						"Advertencia",
						"AlertTriangleIcon",
						`Ejemplar ${opts[0].alias || opts[0].plate} ya se encuentra agregado`
					)
					this.creationModal.loading = false
					return
				}
				this.creationModal.specimens.push(opts[0])
				this.showToast(
					"success",
					"top-right",
					"Exito",
					"CheckIcon",
					`Ejemplar ${opts[0].alias || opts[0].plate} encontrado`
				)
				this.creationModal.loading = false
				return
			}
			this.creationModal.opts = opts
			this.creationModal.loading = false
		},
		selectOpt(specimen) {
			// check duplicated
			if (this.creationModal.specimens.some((s) => s.id == specimen.id)) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`El ejemplar ${specimen.alias || specimen.plate} ya existe en la lista de ejemplares asignados`
				)
				return
			}
			if (specimen.events && specimen.events.length > 0) {
				const specimenActive = specimen.events.find((event) => event.active_fronts > 0)
				if (specimenActive) {
					this.showToast(
						"warning",
						"top-right",
						"Advertencia",
						"AlertTriangleIcon",
						`Ejemplar ${specimen.alias || specimen.plate} esta registrado en la fecha ${moment(
							specimenActive.te_date
						).format("MM-DD-YYYY")} del torneo ${specimenActive.to_name}`
					)
					this.creationModal.loading = false
					return
				}
			}
			if (specimen.recommended && specimen.recommended.length > 0) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`Ejemplar ${specimen.alias || specimen.plate} esta recomendado en la fecha ${moment(
						specimen.recommended[0].te_date
					).format("MM-DD-YYYY")} del torneo ${specimen.recommended[0].to_name}`
				)
				this.loading = false
				return
			}
			this.creationModal.specimens.push({ ...specimen, delete: false })
			this.creationModal.opts = []
		},
		async assignSpecimens() {
			let newsLength = this.creationModal.specimens.filter((it) => !it.old).length
			if (newsLength < 1) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					"No hay nuevos ejemplares para guardar"
				)
				return
			}
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se guardaran ${newsLength} nuevos ejemplares`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			await tournamentService.storeSpecimensEvent({
				body: {
					tournament_event_id: this.info.id,
					specimens: this.creationModal.specimens.filter((it) => !it.delete).map((it) => it.id),
					specimensDelete: this.creationModal.specimens
						.filter((it) => it.delete)
						.map((it) => it.id)
						.join(","),
				},
			})
			this.$emit("refresh")
			this.isActive = false
			this.isPreloading(false)
		},
		async removeFromCreation(idx) {
			const specimen = this.creationModal.specimens[idx]
			if (specimen.status_tournament_event_id == 1) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					"El ejemplar ya fue calificado en un evento."
				)
				return
			}
			if (specimen.old) {
				const { isConfirmed } = await this.showConfirmSwal({
					title: `Seguro de eliminar el ejemplar ${
						specimen.alias || specimen.plate
					}? (fue previamente guardado)`,
				})
				if (!isConfirmed) return
				await tournamentService.deleteSpecimenFromEvent({
					specimen_id: specimen.id,
					tournament_event_id: this.info.id,
				})
				this.$emit("refresh")
			}

			this.creationModal.specimens[idx].delete = true
			this.creationModal.specimens.splice(idx, 1)
		},
		hasRepeated(specimen, arr) {
			return arr.find((el) => el.id == specimen.id) != undefined
		},
		assignSpecimenAlias(specimen, idx) {
			this.assignAliases.info = { specimen, idx }
			this.assignAliases.show = !this.assignAliases.show
		},
		async getRegisteredSpecimens() {
			this.creationModal.loading = true
			const { data } = await tournamentService.getSpecimensToEvent({ id: this.info.id })
			this.creationModal.specimens = data.map((it) => ({ ...it, delete: false, old: true }))
			this.creationModal.loading = false
		},
		setAssignedAlias(alias) {
			const modified = this.creationModal.specimens[this.assignAliases.info.idx]
			if (modified) {
				modified.alias = alias
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.creationModal.title = `Registrar gallos / ${this.info.tournament} / ${
					this.info.coliseum
				} / ${moment(this.info.date).format("MM-DD-YYYY")}`
				this.getRegisteredSpecimens()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.deleteOverlay {
	color: black;
	transition: 0.2s all ease-in-out;
}

.hoverTrigger {
	height: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hoverTriggerResult {
	padding: 1px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hoverTrigger .deleteOverlay {
	opacity: 0.8;
	background: rgba(255, 0, 0, 0.2);
	border-radius: 4px;
}

.deleteOverlaySuccess {
	position: absolute;
	opacity: 0.8;
	right: 5px;
	top: 70px;
	background: rgba(0, 255, 0, 0.2);
	border-radius: 4px;
}

.hoverTrigger .deleteOverlayEdit {
	opacity: 0.8;
	background: rgba(255, 255, 0, 0.2);
	border-radius: 4px;
}
.card-is_nursing {
	position: relative;
	top: -8px;
	right: 0;
}
.title-register-ejemplares {
	font-size: 14px !important;
}
.rec-by {
	position: relative;
	top: -6px;
	right: -119px;
}
.average-top {
	position: relative;
	top: -4px;
	right: -10px;
	font-size: 11px;
}
.card-played {
	position: relative;
	top: 10px;
	right: 115px;
}
.card-specimen {
	position: relative;
	top: -7px;
	right: 65px;
}
.status-label {
	// position: relative;
	// top: -8px;
	// right: 6px;
	text-transform: uppercase;
}
.div-count-played {
	top: -17px;
	right: 163px;
}
</style>
